import {cleanUpHtml, getRelativeLink} from "../utils/string-tools";
import {Link} from "react-router-dom";
import ArrowRightLongIcon from "../ui/icons/ArrowrRightLongIcon";

type Props = {
    content: any
}
const TextWithLink = ({content}: Props) => {
    const text = cleanUpHtml(content.text)


    return <section
        id={content.section_id}
        className={'wrapper relative w-full !py-[8rem] flex flex-col items-center'}
    >
        <div
            className={'flex justify-center text-c-primary dark:text-c-dark-primary w-full md:w-[65%] text-3xl md:text-5xl [&>*]:text-center [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
            dangerouslySetInnerHTML={{__html: text}}/>
        <Link
            to={getRelativeLink(content.link?.url)}
            target={content.link?.target}
            className={'flex items-center gap-5 text-c-tertiary dark:text-c-dark-primary text-base xsl:text-xl uppercase font-medium mt-[4rem]'}
        >
            <ArrowRightLongIcon/>
            {content.link?.title}
        </Link>
    </section>
}

export default TextWithLink;
