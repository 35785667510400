import Menu from '../model/Menu';
import Settings from '../model/Settings';
import Page from '../model/Page';
import Post from '../model/Post';
import FooterMenu from "../model/FooterMenu";
import axios from 'axios';

export async function getAllPages() {
    const pagesRes = await fetch(process.env.REACT_APP_API_URL + '/oo/pages');
    const data = await pagesRes.json();
    return Page.fromApiResponse(data);
}

export async function getAllPosts() {
    const pagesRes = await fetch(process.env.REACT_APP_API_URL + '/oo/posts');
    const data = await pagesRes.json();
    return Post.fromApiResponse(data);
}

export async function getSettings() {
    const settingsRes = await fetch(process.env.REACT_APP_API_URL + '/acf/v3/options/general_settings?_embed');
    const data = await settingsRes.json();
    return Settings.fromApiResponse(data);
}

export const getMenu = async (lang: string) => {
    const menuRes = await fetch(process.env.REACT_APP_API_URL + '/oo/menu?location=topbar' + '&lang=' + lang);
    const data = await menuRes.json();
    return Menu.fromApiResponse(data);
};

export const getFooter = async (lang: string) => {
    const menuRes = await fetch(process.env.REACT_APP_API_URL + '/oo/footer?location=footer' + '&lang=' + lang);
    const data = await menuRes.json();
    return FooterMenu.fromApiResponse(data);
};

export const sendMessage = async (data: any) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/oo/message' : '';
    return await axios.post(url, data);
};
