import {cleanUpHtml} from "../utils/string-tools";
import WPImage from "../model/WPImage";
import ArrowRightLongIcon from "../ui/icons/ArrowrRightLongIcon";
import {useState} from "react";
import ArrowLeftLongIcon from "../ui/icons/ArrowrLeftLongIcon";

type Props = {
    content: any
}
const ProductSection = ({content}: Props) => {
    const description = cleanUpHtml(content.description)
    const [index, setIndex] = useState(0);
    const images = Array.isArray(content.products) && content.products.length > 0 ?
        content.products.map((p: any) => WPImage.fromApiResponse(p).toComponent('w-full my-auto'))
        : [];


    const handleNextIndex = () => {
        if (index < images.length - 1) {
            setIndex(prev => prev + 1)
        } else {
            setIndex(0)
        }
        console.log(index)
    }

    const handlePrevIndex = () => {
        if (index - 1 < 0) {
            console.log(index)
            setIndex(images.length - 1)
        } else {
            setIndex(prev => prev - 1)
        }

    }

    return <section
        id={content.section_id}
        className={'wrapper first:!pt-[10rem] !pb-[20rem] relative w-full'}
    >
        <div
            className={'flex flex-col md:flex-row h-full bg-c-secondary px-[2rem] xsl:px-[4.8rem]'}
        >
            <div
                className={'relative w-full md:w-[50%] order-last md:order-first'}
            >
                <div
                    className={'relative w-full px-[3rem] xsl:px-[4.8rem] py-[3rem] xsl:py-[4rem] md:min-h-[74.8rem] bg-c-tertiary dark:bg-c-dark-tertiary text-c-secondary dark:text-c-dark-primary translate-y-[4.8rem]'}
                >
                    <h1
                        className={'text-2xl md:text-4xl mb-10'}
                    >
                        {content?.title}
                    </h1>
                    <div
                        className={'flex flex-col text-c-secondary text-lg justify-center font-regular text-c-primary dark:text-c-dark-primary w-full'}
                        dangerouslySetInnerHTML={{__html: description}}>
                    </div>
                </div>
            </div>
            <div
                className={'relative flex flex-col justify-center w-full md:w-[50%] py-[4.8rem] md:min-h-[70rem] '}
            >
                {images[index]}
                <div
                    className={'flex justify-center md:justify-end text-c-tertiary dark:text-c-dark-tertiary mt-[4.8rem] md:mt-auto '}
                >
                    <div
                        className={'cursor-pointer'}
                        onClick={handlePrevIndex}
                    >
                        <ArrowLeftLongIcon/>
                    </div>
                    <div
                        className={'ml-10 cursor-pointer'}
                        onClick={handleNextIndex}
                    >
                        <ArrowRightLongIcon/>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default ProductSection;
