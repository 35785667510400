import ArrowDownIcon from "../../ui/icons/ArrowDownIcon";
import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';
import ContentContext from "../../store/content-context";

type Props = {
    isMainPage: boolean
}
const LangSelector = ({isMainPage}: Props) => {
    const navigate = useNavigate();
    const {lang, setLang} = useContext(ContentContext);
    const [langState, setLangState] = useState('PL');
    const [showLangMenu, setShowLangMenu] = useState(false);
    const fontColor = isMainPage ? 'text-c-secondary dark:text-c-dark-primary' : 'text-c-primary dark:text-c-dark-primary '
    const ref = useRef<HTMLUListElement>(null)

    useEffect(() => {
        const handleClick = (e: Event) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setShowLangMenu(false)
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => document.removeEventListener('click', handleClick, true)

    }, [])


    return <div className={'flex relative group my-auto items-center text-lg lg:ml-[8rem] ' + fontColor}>
        <p className={'flex items-center w-[5rem] gap-5 cursor-pointer'}
           onClick={() => setShowLangMenu(!showLangMenu)}
        >
            {lang.toUpperCase()}
            <ArrowDownIcon/>
        </p>
        {showLangMenu && <ul
            ref={ref}
            className={'absolute z-10 -bottom-[50%] w-[70px] right-0 translate-y-full bg-c-secondary dark:bg-c-dark-tertiary text-c-primary dark:text-c-dark-primary flex flex-col items-center cursor-pointer'}>
            <li
                className={'py-2 w-full text-center hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                onClick={() => {
                    setLang('pl')
                    setLangState('PL');
                    setShowLangMenu(false)
                    navigate('/')
                }}
            >
                PL
            </li>
            <li
                className={'py-2 w-full text-center hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                onClick={() => {
                    setLang('en')
                    setLangState('EN');
                    setShowLangMenu(false)
                    navigate('/en')
                }}
            >
                EN
            </li>
        </ul>}
    </div>
}

export default LangSelector;
