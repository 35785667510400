import React, {useContext, useEffect} from 'react';
import {getAllPages, getAllPosts, getFooter, getMenu, getSettings} from './api/content-api';
import ContentContext from './store/content-context';
import {Route, Routes, useLocation} from 'react-router-dom';
import Page from './model/Page';
import ScrollToTop from './pages/ScrollToTop';
import ErrorPage from './pages/ErrorPage';
import {Helmet} from 'react-helmet';

function App() {
    const location = useLocation();
    const {pages, lang, setLang, setPages, setMenu, setFooter, setSettings, fontSize, highContrast} = useContext(ContentContext);

    useEffect(() => {
        document.body.classList.toggle('dark', highContrast);
    }, [highContrast])

    useEffect(() => {
        const fetchData = async () => {
            const pages = await getAllPages();
            const posts = await getAllPosts();
            const settings = await getSettings();
            setPages([...pages, ...posts]);
            setSettings(settings);
        };
        fetchData().catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        let currentLang = 'pl'
        if(location.pathname.includes('/en')) {
            setLang('en')
            currentLang = 'en'
        } else {
            setLang('pl')
            currentLang = 'pl'
        }

        const fetchData = async () => {
            const menu = await getMenu(currentLang);
            const footer = await getFooter(currentLang);
            setMenu(menu);
            setFooter(footer);
        };
        fetchData().catch((error) => console.log(error));
    }, [lang]);

    return (
        <ScrollToTop>
            <Helmet>
                <title>{process.env.REACT_APP_NAME}</title>
                <link
                    rel='icon'
                    href={process.env.PUBLIC_URL + '/favicon.ico'}
                />
                <link
                    rel='manifest'
                    href={process.env.PUBLIC_URL + '/manifest.json'}
                />
                <style type="text/css">{`
                    html {
                        font-size: ${fontSize}%;
                    }
                `}</style>
            </Helmet>
            <Routes>
                {pages.map((pageData: Page) => pageData.toRoute())}
                <Route
                    key={'default'}
                    path={'*'}
                    element={pages.length > 0 ? <ErrorPage/> : <></>}
                />
            </Routes>
        </ScrollToTop>
    );
}

export default App;
