import DOMPurify from 'isomorphic-dompurify';
import fundsFlagEn from "../assets/images/FE-en.jpg";
import ueFlagEn from "../assets/images/UE-en.jpg";
import fundsFlagPl from "../assets/images/FE-pl.jpg";
import ueFlagPl from "../assets/images/UE-pl.jpg";
import allPl from "../assets/images/All-pl.png";
import allEn from "../assets/images/All-en.png";

const months = {
    'sty': 'Jan',
    'lut': 'Feb',
    'mar': 'Mar',
    'kwi': 'Apr',
    'maj': 'May',
    'cze': 'Jun',
    'lip': 'Jul',
    'sie': 'Aug',
    'wrz': 'Sep',
    'paź': 'Oct',
    'lis': 'Nov',
    'gru': 'Dec'
};

export const getFlags = (lang: string) => {
    switch(lang){
        case "en": {
            return {funds: fundsFlagEn, ue: ueFlagEn, all: allEn}
        }
        default: {
            return {funds: fundsFlagPl, ue: ueFlagPl, all: allPl}
        }
    }
}

export const getHomeLink = (lang: string) => {
    switch(lang){
        case "en": {
            return '/en'
        }
        default: {
            return '/'
        }
    }
}

export const getRelativeLink = (url: string | undefined, type?: string | undefined): string => {
    if (url && url !== '/') {
        if (type !== 'custom') {
            return url.replace(/^(?:\/\/|[^/]+)*\//, '/')
        } else {
            return url;
        }
    } else {
        return '';
    }
};

export const removeTrailingSlash = (str: string | undefined) => {
    if (str && str?.endsWith('/')) {
        return str.slice(0, -1);
    }
    return str ? str : '';
};

export const cleanUpHtml = (content: string, config?: DOMPurify.Config): string => {
    return `${DOMPurify.sanitize(content, {
        ...{ADD_ATTR: ['target']},
        ...config,
    })}`;
};

export const getEngDate = (date: string) => {
    const [month, day, year] = date.split(' ');

    // @ts-ignore
    const engMonth = months[month.toLowerCase()];

    return `${engMonth} ${day} ${year}`;
}
