import {useState} from "react";
import Menu from "../../model/Menu";
import useOutsideClick from "../../hooks/useOutsideClick";
import {Link} from "react-router-dom";
import HeaderItem from "./HeaderItem";
import MenuButton from "../Buttons/MenuButton";
import CloseButton from "../Buttons/CloseButton";
import LangSelector from "./LangSelector";
import AccessibilityToolbar from "./AccessibilityToolbar";

interface HeaderProps {
    menu: Menu;
    isMainPage: boolean;
}

const MenuDrawer = ({menu, isMainPage}: HeaderProps) => {
    const [open, setOpen] = useState(false)
    const ref = useOutsideClick({handler: () => setOpen(open => !open)})

    return (
        <>
            <MenuButton isMainPage={isMainPage} onClick={() => setOpen(open => !open)}/>
            <div
                className={`${open ? '' : 'translate-x-full'} lg:hidden fixed top-0 right-0 z-50 h-screen min-w-[50%] overflow-y-auto transition-transform p-18 bg-c-fifth`}>
                <div className='h-full flex flex-col justify-between items-center'>
                    <div className='flex flex-col gap-[10rem] items-center'>
                        <CloseButton onClick={() => setOpen(open => !open)}/>
                        <ul className='flex flex-col justify-center gap-[2.4rem]'>
                            {menu.menuItems.map((it, index) => <HeaderItem whiteText menu={it} key={it.id}
                                                                           onClick={(open: boolean) => setOpen(!open)}/>)}
                        </ul>
                        <div
                            className={'flex flex-col items-center gap-[2.4rem]'}
                        >
                            <LangSelector isMainPage={true}/>
                            <AccessibilityToolbar isMainPage={true} center/>
                        </div>
                    </div>
                    <Link to={'/'} onClick={(open) => setOpen(!open)}>
                        {menu.logo.toComponent('block w-[8rem] h-[6rem] self-end object-contain')}
                    </Link>
                </div>
            </div>
            <div ref={ref}
                 className={`${open ? 'opacity-50 visible pointer-events-auto' : 'opacity-0 invisible pointer-events-none'} transition-opacity duration-500 fixed inset-0 w-screen h-screen bg-c-sixth z-40 m-[0 -4.8rem]`}/>
        </>
    )
}

export default MenuDrawer;
