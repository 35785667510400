import WPImage from "./WPImage";
import {getRelativeLink} from "../utils/string-tools";
import {MenuItem} from "./Menu";

export interface SocialMedia {
    link: string,
    icon: WPImage,
}

class FooterMenu {
    menuItems: MenuItem[];
    logo: WPImage;
    email: string;
    social_media: any;

    constructor(
        menuItems: MenuItem[],
        logo: WPImage,
        email: string,
        social_media: any,
    ) {
        this.menuItems = menuItems;
        this.logo = logo;
        this.email = email;
        this.social_media = social_media;
    }

    public static fromApiResponse(data: any) {
        const menu = data?.menu ? data?.menu
            .filter((item: any) => item.menu_item_parent == 0)
            .sort((a: any, b: any) => a.menu_order - b.menu_order)
            .map((mainItem: any) => {
                return new MenuItem(
                    mainItem.ID,
                    mainItem.title,
                    mainItem.type === 'custom' ? mainItem.url : getRelativeLink(mainItem.url),
                    false,
                    []
                );
            }) : [];

        return new FooterMenu(
            menu,
            WPImage.fromApiResponse(data?.logo),
            data?.email ? data?.email : '',
            data?.social_media ? data?.social_media.map((s: any) => ({
                icon: WPImage.fromApiResponse(s.icon),
                link: s.link
            })) : '',
        );
    }
}

export default FooterMenu;
