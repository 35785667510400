type Props = {
    label: string;
    name: string;
    optional?: boolean;
    type?: string;
    error?: boolean;
    maxLength?: number;
    min?: number;
    onChange: (value: string) => void;
    value: string | number | undefined;
    classes?: string;
    variant?: 'contained' | 'outlined'
}

const TextField = ({label, name, maxLength, optional, value, min, type='text', error=false, onChange, classes, variant='contained'}: Props) => {
    const border = error ? ' border-c-error ' : ' border-c-primary dark:border-c-dark-primary '
    const bg = ' bg-c-secondary dark:bg-c-dark-tertiary'
    const textColor = ' text-c-primary dark:text-c-dark-primary '

    return <div className={'relative bg-transparent ' + classes}>
        <input
            type={type}
            maxLength={maxLength}
            className={'w-full text-xl py-6 outline-none border-b  focus:border-c-tertiary focus:dark:border-c-dark-primary peer ' + border + bg + textColor}
            id={name}
            value={value}
            min={min}
            onChange={(event) => onChange(event.target.value)}
            placeholder={" "}
            name={name}
        />
        <label
            className={'absolute text-sm text-[#ccc] dark:text-c-dark-primary peer-focus:text-c-tertiary peer-focus:dark:text-c-dark-primary font-medium left-0 -top-3 peer-placeholder-shown:hidden peer-focus:block'}
            htmlFor={name}
        >
            {label}
        </label>
        <p className={'text-lg text-[#ccc] dark:text-c-dark-primary pointer-events-none absolute top-6 left-0 hidden peer-focus:hidden peer-placeholder-shown:block'}>
            {label}{!optional && <span className={variant === 'contained' ? 'text-c-tertiary dark:text-c-dark-primary' : 'text-[#ccc]'}> *</span>}
        </p>
    </div>
}

export default TextField;