import WPImage from "./WPImage";
import {getRelativeLink} from "../utils/string-tools";

export class MenuItem {
	id: string;
	title: string;
	url: string;
	newPage: boolean;
	subMenu: MenuItem[] | undefined;

	constructor(id: string, title: string, url: string, newPage: boolean, subMenu?: MenuItem[] | undefined) {
		this.id = id;
		this.title = title;
		this.url = url;
		this.newPage = newPage;
		this.subMenu = subMenu;
	}
}

class Menu {
	menuItems: MenuItem[];
	logo: WPImage;
	darkLogo: WPImage;
	ueLink: string;

	constructor(
		menuItems: MenuItem[],
		logo: WPImage,
		darkLogo: WPImage,
		ueLink: string
	) {
		this.menuItems = menuItems;
		this.logo = logo;
		this.darkLogo = darkLogo;
		this.ueLink = ueLink;
	}

	private static createSubMenu(id: number, data: any) {
		return data
			.filter((item: any) => item.menu_item_parent == id)
			.sort((a: any, b: any) => a.menu_order - b.menu_order)
			.map(
				(item: any) =>
					new MenuItem(
						item.ID,
						item.title,
						getRelativeLink(item.url, item.type),
						item.new_page,
						this.createSubMenu(item.ID, data),
					),
			);
	}

	public static fromApiResponse(data: any) {
		const menu = data?.menu ? data?.menu
			.filter((item: any) => item.menu_item_parent == 0)
			.sort((a: any, b: any) => a.menu_order - b.menu_order)
			.map((mainItem: any) => {
				return new MenuItem(
					mainItem.ID,
					mainItem.title,
					getRelativeLink(mainItem.url, mainItem.type),
					mainItem.new_page,
					this.createSubMenu(mainItem.ID, data?.menu),
				);
			}) : [];
		const ueLink = getRelativeLink(data.ue_link?.url, data.ue_link?.type)

		return new Menu(menu, WPImage.fromApiResponse(data?.logo), WPImage.fromApiResponse(data?.dark_logo), ueLink);
	}
}

export default Menu;
