
class Settings {
	companyName: string;
	street: string;
	buildingNumber: string;
	zipCode: string;
	city: string;
	tin: string;
	contactFormEmail: string;
	email: string;
	phone: any;


	private constructor(companyName: string, street: string, buildingNumber: string, zipCode: string, city: string, tin: string, contactFormEmail: string, email: string, phone: any) {
		this.companyName = companyName;
		this.street = street;
		this.buildingNumber = buildingNumber;
		this.zipCode = zipCode;
		this.city = city;
		this.tin = tin;
		this.contactFormEmail = contactFormEmail;
		this.email = email;
		this.phone = phone;
	}

	public static fromApiResponse({ acf }: any) {
		return new Settings(
			acf.company_name,
			acf.street,
			acf.building_number,
			acf.zip_code,
			acf.city,
			acf.tin,
			acf.contact_form_email,
			acf.email,
			acf.phone,
		);
	}
}

export default Settings;
