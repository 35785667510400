import {useState} from "react";

type Props = {
    label: string;
    name: string;
    rows?: number;
    error?: boolean;
    maxLength?: number;
    onChange: (value: string) => void;
    value: string | number | undefined;
    classes?: string;
}

const TextAreaField = ({label, name, maxLength, rows, value, error=false, onChange, classes}: Props) => {
    const border = error ? 'border-c-error' : 'border-c-primary dark:border-c-dark-primary'
    const [charsLeft, setCharsLeft] = useState(maxLength);

    const handleChange = (event: any) => {
        const value = event.target.value
        maxLength && setCharsLeft(maxLength - value.length)
        if(maxLength && (maxLength - value.length > 0)){
            onChange(value)
        }
    }

    return <div className={'relative ' + classes}>
        <textarea
            maxLength={maxLength}
            className={'w-full dark:text-c-dark-primary resize-none text-xl py-6 bg-c-secondary dark:bg-c-dark-tertiary outline-none border-b focus:border-c-tertiary focus:dark:border-c-dark-primary peer ' + border}
            id={name}
            rows={rows}
            value={value}
            onChange={handleChange}
            placeholder={" "}
            name={name}
        />
        <label
            className={'absolute text-xs text-[#ccc] dark:text-c-dark-primary peer-focus:text-c-tertiary peer-focus:dark:text-c-dark-primary font-medium left-0 -top-3 peer-placeholder-shown:hidden peer-focus:block'}
            htmlFor={name}
        >
            {label}
        </label>
        {label && <p className={'text-[#ccc] dark:text-c-dark-primary text-lg pointer-events-none absolute top-6 left-0 hidden peer-focus:hidden peer-placeholder-shown:block'}>
            {label}<span className={'text-c-tertiary dark:text-c-dark-primary'}> *</span>
        </p>}
        {maxLength && <p className={'absolute bottom-[10px] right-[10px] text-[#ccc] dark:text-c-dark-primary text-xs'}>
            {`${!!value ? value.toString().length : 0}/${maxLength}`}
        </p>}
    </div>
}

export default TextAreaField;