import {createContext, ReactNode, ReducerState, useReducer} from 'react';

type SnackbarContextType = {
	msg: string;
	show: boolean;
	severity: 'error' | 'info' | 'success';
	setMsg: (data: MsgType) => void;
	close: () => void;
};

type MsgType = {
	msg: string;
	severity?: 'error' | 'info' | 'success';
};

type ActionType = {
	type: string;
	value?: MsgType;
};

const SET_MSG = 'setMsg';
const CLOSE = 'close';

const INITIAL_SNACKBAR_STATE: SnackbarContextType = {
	msg: '',
	show: false,
	severity: 'info',
	setMsg: (data: MsgType) => {},
	close: () => {}
};

const contentReducer = (state: SnackbarContextType, action: ActionType): SnackbarContextType => {
	switch (action.type) {
		case SET_MSG: {
			const msg = action.value?.msg ? action.value.msg : ''
			return {
				...state,
				msg: msg,
				show: true,
				severity: action.value?.severity ? action.value?.severity : INITIAL_SNACKBAR_STATE.severity,
			} as SnackbarContextType;
		}
		case CLOSE: {
			return {
				...state,
				show: false,
			};
		}
		default:
			return state;
	}
};

export const SnackbarContextProvider = ({ children }: { children: ReactNode }) => {
	// @ts-ignore
	const [snackbarContext, dispatch] = useReducer(contentReducer, INITIAL_SNACKBAR_STATE as ReducerState<SnackbarContextType>);

	const setMsg = (data: MsgType) => {
		dispatch({ type: SET_MSG, value: data });

		setTimeout(() => {
			dispatch({ type: CLOSE});
		}, 3000)
	};

	const close = () => {
		dispatch({ type: CLOSE});
	};

	const contextValue: SnackbarContextType = {
		...snackbarContext,
		setMsg: setMsg,
		close

	};
	return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
};

const SnackbarContext = createContext<SnackbarContextType>({
	...INITIAL_SNACKBAR_STATE,
});

export default SnackbarContext;
