import {cleanUpHtml, getRelativeLink} from "../utils/string-tools";
import WPImage from "../model/WPImage";
import {Link} from "react-router-dom";
import ArrowRightLongIcon from "../ui/icons/ArrowrRightLongIcon";

type Props = {
    content: any
}
const MultimediaSection = ({content}: Props) => {
    const leftImage = content.left_image ? WPImage.fromApiResponse(content.left_image) : null;
    const bottomImage = content.bottom_image ? WPImage.fromApiResponse(content.bottom_image) : null;
    const cleanedDescription = cleanUpHtml(content.between_description)


    return <section
        id={content.section_id}
        className={'wrapper relative w-full'}
    >
        <div className={'flex flex-col bg-c-secondary p-10'}>
            <div
                className={'flex flex-col md:flex-row h-full'}
            >
                {leftImage && <div
                    className={'flex items-center min-w-[50%] flex-1'}
                >
                    {leftImage.toComponent('w-full')}
                </div>}
                <div
                    className={'flex flex-col p-12 xsl:p-20 justify-center min-h-full bg-c-quaternary dark:bg-c-dark-tertiary min-w-[50%] flex-1'}
                >
                    <h2
                        className={'text-2xl md:text-4xl dark:text-c-dark-primary'}
                    >
                        {content.right_description?.title}
                    </h2>
                    <h2
                        className={'text-xl mt-9 dark:text-c-dark-primary'}
                    >
                        {content.right_description?.description}
                    </h2>
                    <Link
                        to={getRelativeLink(content.right_description?.link?.url)}
                        target={content.right_description?.link?.target}
                        className={'flex items-center gap-5 text-c-tertiary dark:text-c-dark-primary text-base xsl:text-xl uppercase font-medium mt-[5rem]'}
                    >
                        <ArrowRightLongIcon/>
                        {content.right_description?.link?.title}
                    </Link>
                </div>
            </div>
            {content.between_description !== '' && <div
                className={'text-c-primary dark:text-c-dark-primary w-full py-[8rem] dark:bg-c-dark-tertiary text-2xl md:text-4xl [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
                dangerouslySetInnerHTML={{__html: cleanedDescription}}/>}
            {bottomImage && <div
                className={'flex items-center flex-1'}
            >
                {bottomImage.toComponent('w-full')}
            </div>}
        </div>
    </section>
}

export default MultimediaSection;
