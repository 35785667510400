import {useContext, useEffect, useState} from "react";
import FullHeader from "./FullHeader";
import StickyHeader from "./StickyHeader";
import {useLocation} from "react-router-dom";
import ContentContext from "../../store/content-context";


const Header = () => {
    const {menu, highContrast} = useContext(ContentContext);
    const [show, setShow] = useState(false)
    const {pathname} = useLocation();
    const isMainPage = pathname === '/' || pathname === '/en';

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (typeof window !== 'undefined') {
            window.scrollY > 200 ? setShow(true) : setShow(false);
        }
    };

    return (
        <header>
            <FullHeader menu={menu} isMainPage={isMainPage}/>
            <StickyHeader show={show} menu={menu}/>
        </header>
    )
}

export default Header
