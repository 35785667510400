type Props = {
    name: string;
    type?: 'checkbox' | 'radio';
    label: string;
    checked?: boolean;
    onChange: () => void;

    required?: boolean;

    link?: {
        url: string;
        target: string;
    }
    classes?: string;
}
const Checkbox = ({name, type = 'checkbox', label, checked, onChange, link, required, classes}: Props) => {

    const getLabel = () => {
        if(link) {
            return <a className={'underline'} href={link.url} target={link.target}>{label}</a>
        } else {
            return label
        }
    }

    const getRequired = () => {
        if(required) {
            return <span className="text-c-error"> *</span>
        }
    }

    return <label htmlFor={name} onClick={onChange} className={"cursor-pointer relative pl-16 text-c-primary dark:text-c-dark-primary text-lg block w-full " + classes}>
        <input
            type={type}
            onChange={()=> {}}
            checked={checked}
            value={type === 'radio' ? label : undefined}
            id={label}
            className="absolute opacity-0 cursor-pointer h-0 w-0 peer"
        />
        <span className="absolute rounded-md top-0 left-0 w-10 h-10 bg-transparent peer-checked:bg-c-tertiary peer-checked:dark:bg-c-dark-primary peer-checked:border-c-tertiary border border-c-primary"/>
        <svg className="absolute top-3 left-2 w-10 h-10 hidden peer-checked:block" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={'stroke-c-secondary dark:stroke-c-dark-tertiary'} d="M0.944336 6.33331L5.70822 9.66665L12.0554 1.33331" strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
        {getLabel()}
        {getRequired()}
    </label>
}

export default Checkbox;