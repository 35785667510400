import {useContext} from "react";
import ContentContext from "../../store/content-context";
import ArticleCard from "./ArticleCard";
import Post from "../../model/Post";

type Props = {
  content: any
}
const ArticleList = ({content}: Props) => {
  let posts = [];
  const {pages, lang} = useContext(ContentContext);
  const postsRaw = pages.filter((it: Post) => it.type === 'post');
  if(lang === 'en') {
    posts = postsRaw.filter((it: Post) => it.link.includes('/en/'))
  } else {
    posts = postsRaw.filter((it: Post) => !it.link.includes('/en/'))
  }

  return <section
    id={content.section_id}
    className={'wrapper py-[10rem] relative w-full'}
  >
    {content.title && <h1
      className={'text-3xl md:text-4xl font-medium text-c-primary dark:text-c-dark-primary mb-[4.8rem]'}
    >
      {content.title}
    </h1>}
    {posts.length > 0 && <article className={'dark:bg-c-dark-tertiary bg-c-secondary p-[1.2rem] xsl:p-[2.4rem] flex flex-col  md:ml-[-2.4rem] md:mr-[-2.4rem] gap-[4rem]'}>
      {posts.map((it: any, index: number) => {
        if(it.blocks && it.blocks[0].content) {
          return <ArticleCard props={it.blocks[0].content} key={index} />
        }
      })}
    </article>}
  </section>
}

export default ArticleList;
