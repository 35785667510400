import {MenuItem} from "../../model/Menu";
import {Link, useLocation} from "react-router-dom";

interface HeaderItemProps {
    menu: MenuItem;
    whiteText?: boolean;
    onClick?: any;
}

const HeaderItem = ({menu, whiteText, onClick}: HeaderItemProps) => {
    const {pathname} = useLocation()
    const fontColor = whiteText ? 'text-c-secondary dark:text-c-dark-secondary ' : 'text-c-primary dark:text-c-dark-primary '
    const lineColor = whiteText ? 'after:bg-c-secondary dark:text-c-dark-secondary ' : 'after:bg-c-primary after:dark:bg-c-dark-primary '

    return (
        <li className='text-center lg:text-left lg:mr-[4rem] last:mr-0' onClick={onClick}>
            <Link to={menu.url} target={menu.newPage ? '_blank' : ''}
                  className={`${pathname === menu.url ? "font-bold underline underline-offset-[.3rem] after:content-none pointer-events-none cursor-auto" : "font-medium"} relative text-sm sm:text-lg lg:text-xl uppercase after:content-[''] after:absolute after:-bottom-[1px] after:left-0 after:h-[.2rem] after:w-0 after:bg-c-primary after:bg-c-dark-primary hover:after:w-full after:transition-width after:ease after:duration-300 ` + fontColor + lineColor}>
                {menu.title}
            </Link>
        </li>
    )
}

export default HeaderItem
