import WPImage from "../../model/WPImage";
import {getEngDate, getRelativeLink} from "../../utils/string-tools";
import ArrowRightLongIcon from "../../ui/icons/ArrowrRightLongIcon";
import {Link} from "react-router-dom";
import {useContext} from "react";
import ContentContext from "../../store/content-context";

type Props = {
  props: any;
}

const ArticleCard = ({props}: Props) => {
  const {lang} = useContext(ContentContext);
  const image = props.image ? WPImage.fromApiResponse(props.image) : null;
  const authorImage = props.authors[0].image ? WPImage.fromApiResponse(props.authors[0].image) : null;
  const date = lang === 'en' ? getEngDate(props.article_date) : props.article_date;

  return <div className={'flex flex-col md:flex-row dark:bg-c-dark-tertiary bg-c-quaternary'}>
    {image && <div className={'flex items-center flex-[1_0_40%]'}>
      {image.toComponent('w-full md:h-full object-cover sm:max-h-[40rem] md:max-h-none')}
    </div>}
    <div className={'flex flex-col flex-[1_0_50%] gap-[1.6rem] p-[1.8rem] xsl:p-[2.4rem]'}>
      <div className={'flex items-center flex-wrap gap-[.8rem]'}>
        {authorImage && <div className={'w-[4.8rem] h-[4.8rem] rounded-[50%] bg-c-tertiary'}>
          {authorImage.toComponent('w-[4.8rem] h-[4.8rem] rounded-[50%] object-cover')}
        </div>}
        <div className={'flex flex-col'}>
          {props.authors[0].name && <p className={'text-base text-c-primary dark:text-c-dark-primary overflow-break break-all'}>{props.authors[0].name}</p>}
          <div className={'flex flex-wrap items-center gap-[.8rem]'}>
            {props.article_date && <p className={'text-base text-c-primary dark:text-c-dark-primary'}>{date.charAt(0).toUpperCase() + date.slice(1)}</p>}
            {props.read_time && <span className={'w-[.4rem] h-[.4rem] rounded-[50%] bg-c-primary dark:bg-c-dark-primary'}></span>}
            {props.read_time && <p className={'text-base text-c-primary dark:text-c-dark-primary'}>{props.read_time}</p>}
          </div>
        </div>
      </div>
      {props.post_title && <h2 className={'text-[3.2rem] dark:text-c-dark-primary c-primary'}>{props.post_title}</h2>}
      {props.short_description && <p className={'text-xl dark:text-c-dark-primary c-primary'}>{props.short_description}</p>}
      <Link
        to={getRelativeLink(props.link)}
        className={'flex items-center self-end gap-5 text-c-primary dark:text-c-dark-primary text-base xsl:text-xl uppercase font-medium mt-[4rem]'}
      >
        <ArrowRightLongIcon/>
        {props.text_link}
      </Link>
    </div>
  </div>
}

export default ArticleCard;
