import {createRef, useContext, useState} from "react";
import TextField from "../../components/TextField";
import isEmail from 'validator/lib/isEmail';
import TextAreaField from "../../components/TextAreaField";
import Checkbox from "../../components/Checkbox";
import ArrowRightLongIcon from "../../ui/icons/ArrowrRightLongIcon";
import SnackbarContext from "../../store/snackbar-context";
import {sendMessage} from "../../api/content-api";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "../../components/CircularProgress";
import {getRelativeLink} from "../../utils/string-tools";


type Props = {
    content: any
}

const Form = ({content}: Props) => {
    const INITIAL_FORM_STATE = {
        fullName: {value: '', error: content.full_name_error},
        email: {value: '', error: content.email_error},
        phone: {value: '', error: content.phone_error},
        message: {value: '', error: content.message_error},
    }
    const [formState, setFormState] = useState({...INITIAL_FORM_STATE});
    const [validated, setValidated] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const {setMsg} = useContext(SnackbarContext);
    const ref = createRef<ReCAPTCHA>();

    const handleValueChange = (accessor: keyof typeof INITIAL_FORM_STATE, value: string | number, validation: (value: string | number) => boolean) => {
        if (validation(value)) {
            setFormState((prev) => ({
                ...prev,
                [accessor]: {value: value, error: null}
            }))
        } else {
            setFormState((prev) => ({
                ...prev,
                [accessor]: {value: value, error: INITIAL_FORM_STATE[accessor].error}
            }))
        }
    }

    const validate = (data: any) => {
        setValidated(true);
        for (const key in data) {
            if ((data[key].optional && data[key].value) || !data[key].optional) {
                if (data[key].error !== null && data[key].error !== undefined) {
                    setMsg({msg: data[key].error, severity: 'error'})
                    return false;
                }
            }
        }
        return true;
    }

    const handleSend = async () => {

        if (validate(formState)) {
            if (!checkbox) {
                setMsg({msg: content.consent_error, severity: 'error'});
                return;
            }
            const request = {};
            for (const key in formState) {
                if (!request.hasOwnProperty(key)) {
                    // @ts-ignore
                    request[key] = formState[key].value;
                }
            }
            setSendingRequest(true)
            //@ts-ignore
            request['token'] = await ref.current.executeAsync();
            sendMessage(request)
                .then((response: any) => {
                    if (response.status === 200) {
                        setMsg({msg: content.form_sucess, severity: 'success'});
                        setValidated(false);
                    } else {
                        console.log(response);
                        setMsg({msg: response.data, severity: 'error'});
                    }
                    setFormState(INITIAL_FORM_STATE)
                    setCheckbox(false)
                    setSendingRequest(false)
                })
                .catch((exception: any) => {
                    console.error(exception);
                    const msg = exception?.response?.data?.message ? exception.response.data.message : content.form_error
                    setMsg({msg, severity: 'error'});
                    setFormState(INITIAL_FORM_STATE)
                    setCheckbox(false)
                    setSendingRequest(false);
                });
        }
    }


    return <div
        className={'w-full md:w-[60%] bg-c-secondary dark:bg-c-dark-tertiary p-12 xsl:p-24'}
    >

        <TextField
            label={content.full_name_label}
            error={validated && !!formState.fullName.error}
            name={'full-name'}
            value={formState.fullName.value}
            onChange={(value) => handleValueChange('fullName', value, value => value.toString().length > 1)}
            classes={'mt-8'}
        />
        <TextField
            label={content.email_label}
            error={validated && !!formState.email.error}
            type={'email'}
            name={'email'}
            value={formState.email.value}
            onChange={(value) => handleValueChange('email', value, value => isEmail(value.toString()))}
            classes={'mt-8'}
        />
        <TextField
            label={content.phone_label}
            error={validated && !!formState.phone.error}
            type={'tel'}
            name={'phone'}
            value={formState.phone.value}
            onChange={(value) => handleValueChange('phone', value, value => value.toString().length > 8)}
            classes={'mt-8'}
        />
        <TextAreaField
            label={content.message_label}
            maxLength={3000}
            rows={7}
            error={validated && !!formState.message.error}
            name={'teachers-parents-cooperation-question'}
            value={formState.message.value}
            onChange={(value) => handleValueChange('message', value, value => value.toString().length > 1)}
            classes={'mt-8'}
        />
        <Checkbox
            name={'privacy-policy'}
            type={'checkbox'}
            link={{url: getRelativeLink(content.consent_link?.url), target: '_blank'}}
            checked={checkbox}
            label={content.consent_text}
            onChange={() => setCheckbox(!checkbox)}
            classes={'mb-[7rem] mt-16'}
        />
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : ''}
            size={'invisible'}
            ref={ref}
        />
        <div
            className={'flex items-center gap-5 text-c-tertiary dark:text-c-dark-primary text-lg uppercase font-medium cursor-pointer'}
            onClick={() => !sendingRequest && handleSend()}
        >
            <div
                className={'ml-auto'}
            >
                {sendingRequest ? <CircularProgress size={20}/> :
                    <ArrowRightLongIcon/>}
            </div>
            {content.send_button}
        </div>
    </div>
}

export default Form;
