import {cleanUpHtml, getRelativeLink} from "../utils/string-tools";
import WPImage from "../model/WPImage";
import {Link} from "react-router-dom";
import ArrowRightLongIcon from "../ui/icons/ArrowrRightLongIcon";

type Props = {
  content: any
}
const CartWithTextImage = ({content}: Props) => {
  const rightImage = content.right_image ? WPImage.fromApiResponse(content.right_image) : null;
  const cleanText = cleanUpHtml(content.left_description?.description ? content.left_description?.description : '')

  return <section id={content.section_id} className={'wrapper relative w-full'}>
    <div className={'flex flex-col'}>
      <div className={'flex bg-c-fifth dark:bg-c-dark-tertiary flex-col md:flex-row h-full'}>
        <div className={'flex flex-col p-12 xsl:p-20 justify-center min-h-full min-w-[50%] flex-1'}>
          {content.left_description?.title && <h2 className={'text-c-secondary dark:text-c-dark-primary text-2xl md:text-4xl'}>
            {content.left_description?.title}
          </h2>}
          {cleanText && <div className={'mt-[2.4rem] text-c-secondary dark:text-c-dark-primary w-full md:w-[80%] text-xl [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
            dangerouslySetInnerHTML={{__html: cleanText}}
          />}
          {content.left_description?.link?.url && <Link
            to={getRelativeLink(content.left_description?.link?.url)}
            target={content.left_description?.link?.target}
            className={'flex items-center gap-5 text-c-tertiary dark:text-c-dark-primary text-base xsl:text-xl uppercase font-medium mt-[5rem]'}
          >
            <ArrowRightLongIcon/>
            {content.left_description?.link?.title}
          </Link>}
        </div>
        {rightImage && <div className={'flex items-center min-w-[50%] flex-1'}>
          {rightImage.toComponent('w-full md:h-full object-cover')}
        </div>}
      </div>
    </div>
  </section>
}

export default CartWithTextImage;
