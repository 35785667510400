class ImageSize {
    url: string;
    width: number;
    height: number;

    constructor(url: string, width: number, height: number) {
        this.url = url;
        this.width = width;
        this.height = height;
    }
}

class WPImage {
    id: number;
    type: string;
    alt: string;
    filename: string;
    sizes: {
        original: ImageSize;
        thumbnail: ImageSize;
        medium: ImageSize;
        large: ImageSize;
    };

    constructor(id: number, type: string, alt: string, filename: string, sizes: { original: ImageSize; thumbnail: ImageSize; medium: ImageSize; large: ImageSize }) {
        this.id = id;
        this.type = type;
        this.alt = alt;
        this.filename = filename;
        this.sizes = sizes;
    }

    public static fromApiResponse(data: any) {
        return new WPImage(
            data?.id ? data.id : -1,
            data?.subtype ? data?.subtype : '',
            data?.alt ? data?.alt : 'image',
            data?.filename ? data?.filename : '',
            {
                original: new ImageSize(
                    data?.url ? data?.url : '',
                    data?.width ? data?.width : 0,
                    data?.height ? data?.height : 0
                ),
                thumbnail: new ImageSize(
                    data?.sizes?.thumbnail ? data?.sizes?.thumbnail : '',
                    data?.sizes?.['thumbnail-width'] ? data?.sizes?.['thumbnail-width'] : 0,
                    data?.sizes?.['thumbnail-height'] ? data?.sizes?.['thumbnail-height'] : 0
                ),
                medium: new ImageSize(
                    data?.sizes?.medium ? data?.sizes?.medium : '',
                    data?.sizes ?.['medium-width'] ? data?.sizes?.['medium-width'] : 0,
                    data?.sizes?.['medium-height'] ? data?.sizes?.['medium-height'] : 0
                ),
                large: new ImageSize(
                    data?.sizes?.large ? data?.sizes?.large : '',
                    data?.sizes?.['large-width'] ? data?.sizes?.['large-width'] : 0,
                    data?.sizes?.['large-height'] ? data?.sizes?.['large-height'] : 0
                ),
            }
        )
    }

    public toComponent(style?: any, imageStyle?: any) {
        return (
            <picture className={'dark:contrast-150 ' + style}>
                {this.type !== 'gif' && <>
                    <source srcSet={`${this.sizes.original.url}`} media={'(min-width: 1501px)'}/>
                    <source srcSet={`${this.sizes.thumbnail.url} 1x, ${this.sizes.medium.url} 2x`}
                    media={'(max-width: 480px)'}/>
                    <source srcSet={`${this.sizes.medium.url} 1x, ${this.sizes.large.url} 2x`}
                    media={'(max-width: 1200px)'}/>
                    <source srcSet={`${this.sizes.large.url} 1x, ${this.sizes.original.url} 2x`}
                    media={'(max-width: 1500px)'}/>
                    </>}
                <img className={style + ' ' + imageStyle}
                     srcSet={`${this.sizes.original.url} 1x, ${this.sizes.original.url} 2x`}
                     alt={this.alt}/>
            </picture>
        )
    }
}

export default WPImage;
