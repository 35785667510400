import Block from '../model/Block';
import Banner from "./Banner";
import TextWithHighlights from './TextWithHighlights';
import MultimediaSection from "./MultimediaSection";
import TextWithLink from './TextWithLink';
import ContactForm from './ContactForm/ContactForm';
import TextWithBigImage from "./TextWithBigImage";
import CartWithTextImage from "./CartWithTextImage";
import ProductSection from "./ProductSection";
import TextSection from "./TextSection";
import ArticleList from "./Article/ArticleList";
import PostContent from "./PostContent";

const getBlocks = (blocks: Block[]) => {
    return (
        blocks &&
        blocks.map((block, index) => {
            switch (block.component) {
                case 'banner': return <Banner content={block.content} key={'banner-' + index}/>
                case 'text_with_highlights': return <TextWithHighlights content={block.content} key={'text-with-highlights-' + index}/>
                case 'multimedia_section': return <MultimediaSection content={block.content} key={'multimedia-section-' + index}/>
                case 'text_with_link': return <TextWithLink content={block.content} key={'text-with-link-' + index}/>
                case 'contact_form': return <ContactForm content={block.content} key={'contact-form-' + index}/>
                case 'text_with_big_image': return <TextWithBigImage content={block.content} key={'text-with-big-image-' + index}/>
                case 'cart_with_text_image': return <CartWithTextImage content={block.content} key={'cart-with-text-image-' + index}/>
                case 'products_section': return <ProductSection content={block.content} key={'product-section-' + index}/>
                case 'text_section': return <TextSection content={block.content} key={'text-section-' + index}/>
                case 'article_list': return <ArticleList content={block.content} key={'article-list' + index}/>
                case 'post_content': return <PostContent content={block.content} key={'post-content-' + index}/>
                default:
                    return;
            }
        })
    );
};

export default getBlocks;
