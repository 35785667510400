import {Link} from "react-router-dom";
import HeaderItem from "./HeaderItem";
import MenuDrawer from "./MenuDrawer";
import Menu, {MenuItem} from "../../model/Menu";
import LangSelector from "./LangSelector";
import AccessibilityToolbar from "./AccessibilityToolbar";
import {useContext} from "react";
import ContentContext from "../../store/content-context";
import {getHomeLink} from "../../utils/string-tools";

interface HeaderProps {
    show: boolean;
    menu: Menu;
}

const StickyHeader = ({show, menu}: HeaderProps) => {
    const {highContrast, lang} = useContext(ContentContext);

    return (
        <div
            className={`${show ? 'translate-y-0' : '-translate-y-[100%]'} fixed w-full top-0 left-0 bg-c-secondary dark:bg-c-dark-tertiary z-30 transition-all duration-700`}>
            <nav className={'wrapper mx-auto flex justify-between'}>
                <Link to={getHomeLink(lang)} className={'grow'}>
                    {!highContrast && menu.darkLogo.toComponent('h-[4rem] block object-contain my-[1rem] ')}
                    {highContrast && menu.logo.toComponent('h-[4rem] block object-contain my-[1rem] ')}
                </Link>
                <ul className={'xs:hidden lg:flex py-[1rem] px-[8rem] items-center'}>
                    {menu.menuItems.map((it: MenuItem, index: number) => <HeaderItem menu={it} key={it.id}
                                                                                     whiteText={false}/>)}
                </ul>
                <div className={'xs:hidden lg:flex gap-[5rem]'}>
                    <LangSelector isMainPage={false}/>
                    <AccessibilityToolbar isMainPage={false}/>
                </div>
                <MenuDrawer menu={menu} isMainPage={false}/>
            </nav>
        </div>
    )
}

export default StickyHeader
