import {useEffect, useRef} from "react";

type ActionProps = {
  handler: () => void;
}

const useOutsideClick = ({handler}: ActionProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {

      const handleClick = (e: Event) => {
        if(ref.current && ref.current.contains(e.target as Node)) {
          handler()
        }
      }

      document.addEventListener('click', handleClick, true)

      return () => document.removeEventListener('click', handleClick, true)

  }, [handler])

  return ref
}

export default useOutsideClick
