import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    } else {
      const target = document.getElementById(location.hash.replaceAll('#',''))
      if(target) {
        target.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
