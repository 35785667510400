import {useContext, useEffect, useRef, useState} from "react";
import {useLocation} from 'react-router-dom';
import ContentContext from "../../store/content-context";
import AccessibilityIcon from "../../ui/icons/AccessibilityIcon";
import HighContrastIcon from "../../ui/icons/HighContrastIcon";
import MinusIcon from "../../ui/icons/MinusIcon";
import FontSizeIcon from "../../ui/icons/FontSizeIcon";
import PlusIcon from "../../ui/icons/PlusIcon";

type Props = {
    isMainPage: boolean;
    center?: boolean;
}
const AccessibilityToolbar = ({isMainPage, center}: Props) => {
    const {pathname} = useLocation();
    const {increaseFontSize, decreaseFontSize, resetFontSize, fontSize, toggleHighContrast, lang} = useContext(ContentContext);
    const [showMenu, setShowMenu] = useState(false);
    const fontColor = isMainPage ? 'text-c-secondary dark:text-c-dark-primary' : 'text-c-primary dark:text-c-dark-primary '
    const ref = useRef<HTMLDivElement>(null)

    const position = center ? ' right-0 translate-x-[45%] ' : ' right-0 '

    const getHighContrastLabel = () => {
        switch(lang) {
            case "en": return "High contrast"
            default: return "Wysoki kontrast"
        }
    }


    useEffect(() => {
        const handleClick = (e: Event) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setShowMenu(false)
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => document.removeEventListener('click', handleClick, true)

    }, [])


    return <div className={'flex relative my-auto items-center text-lg' + fontColor}>
        <button
            className={fontColor}
            aria-label={'accessibility-menu'}
            onClick={() => setShowMenu(!showMenu)}
        >
            <AccessibilityIcon/>
        </button>
        {showMenu && <div
            ref={ref}
            className={'absolute -bottom-[45%] min-w-[70px] translate-y-full bg-c-secondary dark:bg-c-dark-tertiary text-c-primary z-50 dark:text-c-dark-primary flex flex-col items-center cursor-pointer' + position}>
            <button
                className={'p-8 w-full dark:text-c-dark-primary text-lg flex whitespace-nowrap items-center gap-5 hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                aria-label={'high-contrast-button'}
                onClick={() => toggleHighContrast()}
            >
                <HighContrastIcon/>
                {getHighContrastLabel()}
            </button>
            <div
                className={'w-full text-lg flex whitespace-nowrap items-center gap-5'}
            >
                <button
                    className={'w-full py-8 text-lg flex justify-center items-center gap-5 hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                    aria-label={'decrease-font-size'}
                    onClick={() => decreaseFontSize()}
                >
                    <MinusIcon/>
                </button>

                <button
                    aria-label={'reset-font-size'}
                    className={'w-full min-h-full flex flex-col justify-center items-center hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                    onClick={() => resetFontSize()}
                >
                    <FontSizeIcon/>
                    <p
                        className={'text-xs'}
                    >
                        {100 + fontSize - 62.5}%
                    </p>
                </button>
                <button
                    className={'w-full py-8 text-lg flex justify-center items-center gap-5 hover:bg-c-quaternary hover:dark:bg-c-dark-tertiary'}
                    aria-label={'increase-font-size'}
                    onClick={() => increaseFontSize()}
                >
                    <PlusIcon/>
                </button>

            </div>
        </div>}
    </div>
}

export default AccessibilityToolbar;
