import {Link} from "react-router-dom";
import {useContext} from "react";
import ContentContext from "../../store/content-context";
import Form from "./Form";

type Props = {
    content: any
}
const ContactForm = ({content}: Props) => {
    const {settings, lang} = useContext(ContentContext);

    const formatAddress = () => {
        return `${settings.street} ${settings.buildingNumber}, ${settings.zipCode} ${settings.city}`
    }

    const getPhoneLabel = () => {
        switch(lang) {
            case "en": return "Phone number:"
            default: return "Numer telefonu:"
        }
    }

    return <section
        id={content.section_id}
        className={'wrapper relative w-full !py-[10rem] flex flex-col md:flex-row gap-12'}
    >
        <div
            className={'w-full md:w-[45%] flex flex-col gap-[4rem] justify-end'}
        >
            <h1
                className={'text-3xl md:text-4xl font-medium'}
            >
                {content.title}
            </h1>
            <div
                className={'bg-c-tertiary dark:bg-c-dark-tertiary flex flex-col justify-center px-20 py-[8rem]'}
            >
                <p
                    className={'text-c-secondary dark:text-c-dark-secondary font-semibold text-xl'}
                >
                    {settings.companyName}
                </p>
                <p
                    className={'text-c-secondary dark:text-c-dark-secondary text-xl'}
                >
                    {formatAddress()}
                </p>
                <p
                    className={'text-c-secondary dark:text-c-dark-secondary text-xl mt-[4rem]'}
                >
                    {`NIP: ${settings.tin}`}
                </p>
                <Link to={'tel:' + settings.phone}
                      className='text-xl text-c-secondary dark:text-c-dark-secondary'>
                    {`${getPhoneLabel()} ${settings.phone}`}
                </Link>
                <Link to={'mailto:' + settings.email}
                      className='overflow-break text-xl text-c-secondary dark:text-c-dark-secondary'>
                    {`E-mail: ${settings.email}`}
                </Link>
            </div>
        </div>
        <Form content={content}/>
    </section>
}

export default ContactForm;
