import MainLayout from '../layout/MainLayout';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
	return (
		<MainLayout>
			<div
				className={'flex flex-col justify-center items-center w-full h-screen'}
			>
				<h1 className={'text-6xl dark:text-[#fff]'}>NIE ZNALEZIONO STRONY</h1>
				<Link
					to={'/'}
				>
					<h3
						className={'text-lg text-c-tertiary cursor-pointer underline dark:text-c-dark-primary'}
					>
						Wróć do strony głównej
					</h3>
				</Link>
			</div>
		</MainLayout>
	);
};

export default ErrorPage;
