import {ReactNode} from 'react';
import Header from '../components/Header/Header';
import CustomSnackbar from '../components/UI/CustomSnackbar';
import Footer from "../components/Footer";

const MainLayout = ({children}: { children: ReactNode }) => {

    return (
        <>
            <Header/>
            <main className={'dark:bg-c-dark-tertiary'}>{children}</main>
            <CustomSnackbar/>
            <Footer/>
        </>
    );
};

export default MainLayout;
