const HighContrastIcon = () => {
	return (
		<svg fill="currentColor" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
			 viewBox="0 0 180 180">
<path d="M0,90c0,49.626,40.374,90,90,90s90-40.374,90-90S139.626,0,90,0S0,40.374,0,90z M90,20v140c-38.598,0-70-31.402-70-70
	S51.402,20,90,20z"/>
</svg>
	);
};

export default HighContrastIcon;
