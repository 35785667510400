import {cleanUpHtml} from "../utils/string-tools";

type Props = {
    content: any
}
const TextWithHighlights = ({content}: Props) => {
    const cleanedLeft = cleanUpHtml(content.left_text)
    const cleanedRight = cleanUpHtml(content.right_text)


    return <section
        id={content.section_id}
        className={'wrapper relative w-full'}
    >
        <div className={'my-[10rem] h-full flex flex-col md:flex-row gap-[5rem] md:gap-[12rem]'}>
            <div
                className={'text-c-primary dark:text-c-dark-primary w-full md:w-[65%] text-3xl md:text-5xl [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
                dangerouslySetInnerHTML={{__html: cleanedLeft}}/>
            <div
                className={'text-c-primary dark:text-c-dark-primary flex-1 text-xl [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
                dangerouslySetInnerHTML={{__html: cleanedRight}}/>
        </div>
    </section>
}

export default TextWithHighlights;
