import {Link} from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import Menu, {MenuItem} from "../../model/Menu";
import HeaderItem from "./HeaderItem";
import LangSelector from "./LangSelector";
import AccessibilityToolbar from "./AccessibilityToolbar";
import {useContext} from "react";
import ContentContext from "../../store/content-context";
import {getFlags, getHomeLink} from "../../utils/string-tools";

interface HeaderProps {
    menu: Menu;
    isMainPage: boolean;
}

const FullHeader = ({menu, isMainPage}: HeaderProps) => {
    const {highContrast, lang} = useContext(ContentContext);
    const borderColor = isMainPage ? ' border-c-secondary ' : ' border-c-primary '
    // const bgColor = isMainPage ? 'bg-c-secondary bg-opacity-100 ' : ''
    const bgColor = ' bg-[#fff] '
    const positionStyle = isMainPage ? ' absolute top-0 left-0' : ''

    return (
        <div
            className={'w-full bg-transparent dark:bg-c-dark-tertiary z-30 border-solid border-b ' + borderColor + positionStyle}>
            <div className={'w-full border-solid border-b ' + bgColor + borderColor}>
                <Link to={menu.ueLink} className={'flex wrapper justify-end'}>
                    <img src={getFlags(lang).funds} alt={'ue-funds'}
                         className={'h-[5rem] md:h-[6rem] lg:h-[7rem] mr-[2.5rem]'}/>
                    <img src={getFlags(lang).ue} alt={'ue-flag'} className={'h-[5rem] md:h-[6rem] lg:h-[7rem]'}/>
                </Link>
            </div>
            <nav className={'wrapper mx-auto flex justify-between '}>
                <Link to={getHomeLink(lang)} className={'grow'}>
                    {(isMainPage || highContrast) && menu.logo.toComponent('h-[4rem] md:h-[5rem] lg:h-[7rem] block object-contain my-[1rem] ')}
                    {(!isMainPage && !highContrast) && menu.darkLogo.toComponent('h-[4rem] md:h-[5rem] lg:h-[7rem] block object-contain my-[1rem] ')}
                </Link>
                <ul className={'xs:hidden lg:flex py-[3.5rem] px-[8rem] items-center border-solid border-x ' + borderColor}>
                    {menu.menuItems.map((it: MenuItem, index: number) => <HeaderItem menu={it} key={it.id}
                                                                                     whiteText={isMainPage}/>)}
                </ul>
                <div className={'xs:hidden lg:flex gap-[5rem]'}>
                    <LangSelector isMainPage={isMainPage}/>
                    <AccessibilityToolbar isMainPage={isMainPage}/>
                </div>
                <MenuDrawer menu={menu} isMainPage={isMainPage}/>
            </nav>
        </div>
    )
}

export default FullHeader
