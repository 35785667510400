import {cleanUpHtml, getFlags} from "../utils/string-tools";
import {useContext} from "react";
import ContentContext from "../store/content-context";

type Props = {
    content: any
}
const TextSection = ({content}: Props) => {
    const text = cleanUpHtml(content.description)
    const {lang} = useContext(ContentContext);

    return <section
        id={content.section_id}
        className={'wrapper !max-w-[797px] !py-[10rem] relative w-full'}
    >
        <h1
            className={'text-3xl md:text-5xl font-bold text-c-primary dark:text-c-dark-primary mb-[4.8rem]'}
        >
            {content.title}
        </h1>
        <div
            className={'wysiwyg wysiwyg-c-primary dark:wysiwyg-dark dark:wysiwyg-c-dark-primary w-full text-lg'}
            dangerouslySetInnerHTML={{__html: text}}/>
        {content.bottom_stripe && <div
            className={'border-t border-c-tertiary dark:border-c-dark-primary mt-[4.8rem]'}
        >
            <img src={getFlags(lang).all} alt={'ue-funds'}
                 className={'dark:bg-[#fff] h-[5rem] md:h-[6rem] lg:h-[7rem] mr-[2.5rem]'}/>
        </div>}
    </section>
}

export default TextSection;
