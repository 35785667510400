import {Link} from "react-router-dom";
import {MenuItem} from "../model/Menu";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import {SocialMedia} from "../model/FooterMenu";
import {getHomeLink} from "../utils/string-tools";


const Footer = () => {
    const {footer: menu, lang} = useContext(ContentContext);

    return (
        <footer
            id={'footer'}
            className={'w-full bg-c-fifth dark:bg-c-dark-tertiary'}>
            <div
                className={`wrapper relative flex flex-col lg:flex-row w-full flex-wrap bg-c-fifth  dark:bg-c-dark-tertiary py-[4.8rem] justify-between`}>
                <div
                    className='flex flex-col items-center lg:items-start justify-start gap-[2rem]'>
                    {menu.logo &&
                        <Link to={getHomeLink(lang)}>
                            {menu.logo.toComponent('w-full xsl:w-[26rem]')}
                        </Link>
                    }
                    <div
                        className={'flex justify-start gap-10'}
                    >
                        {Array.isArray(menu.social_media) && menu.social_media.length > 0 && menu.social_media.map((s: SocialMedia, index: number) => (
                            <Link to={s.link} target={'_blank'} key={index}>
                                <img src={s.icon?.sizes?.original?.url} alt={s.icon?.alt}/>
                            </Link>
                        ))}
                    </div>

                </div>
                <div
                    className='flex justify-between flex-col items-center lg:items-start mt-[4rem] lg:mt-0 grow gap-[4rem] md:mx-[10rem]'>
                    {Array.isArray(menu.menuItems) && menu.menuItems.length > 1 && <nav>
                        <ul className='flex flex-col items-start gap-[2.4rem]'>
                            {menu.menuItems.map((it: MenuItem, index: number) => {
                                return (
                                    <Link to={it.url} key={'menu-' + index}>
                                        <p className={'text-lg xsl:text-xl uppercase dark:text-c-dark-secondary text-c-secondary'}>
                                            {it.title}
                                        </p>
                                    </Link>
                                )
                            })}
                        </ul>
                    </nav>}

                </div>
                {menu.email && <div className='flex flex-col items-center lg:items-start mt-[8rem] lg:mt-0 justify-end'>
                    <Link to={'mailto:' + menu.email}
                          className='text-2xl md:text-4xl text-c-secondary dark:text-c-dark-secondary font-medium'>{menu.email}</Link>
                </div>}
                <p
                    className={'dark:text-c-dark-secondary text-c-secondary w-full text-center lg:text-start text-sm mt-[8rem] lg:mt-10'}
                >
                    SEAWARENESS 2023. All rights reserved.
                </p>

            </div>
        </footer>
    )
}

export default Footer
