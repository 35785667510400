type ActionProps = {
  onClick: any;
}

const CloseButton = ({onClick}: ActionProps) => {
  return (
    <button className="close__button" onClick={onClick}></button>
  )
}

export default CloseButton
