import WPImage from "../model/WPImage";
import {useMediaQuery} from 'react-responsive'
import {cleanUpHtml, getRelativeLink} from "../utils/string-tools";
import {Link} from "react-router-dom";
import ArrowDownLongIcon from "../ui/icons/ArrowDownLongIcon";

type Props = {
    content: any
}
const Banner = ({content}: Props) => {
    const cleanedTitle = cleanUpHtml(content.title)
    const bgImage = WPImage.fromApiResponse(content.background_image)
    const original = useMediaQuery({query: '(min-width: 1501px)'})
    const large = useMediaQuery({query: '(max-width: 1500px)'})

    const getBgImage = () => {
        if (original) {
            return bgImage.sizes.original.url
        }
        if (large) {
            return bgImage.sizes.large.url
        }
    }

    return <section
        id={content.section_id}
        style={{backgroundImage: `url("${getBgImage()})"`}}
        className={'relative w-full h-screen dark:contrast-150 overflow-hidden bg-cover bg-center md:bg-left-bottom bg-no-repeat'}
    >
        <div className={'wrapper h-full flex flex-col justify-center sm:justify-end items-start pb-[8rem]'}>
            <div
                className={'text-4xl sm:text-6xl font-medium text-c-secondary dark:text-c-dark-primary'}
                dangerouslySetInnerHTML={{__html: cleanedTitle}}/>
            <Link
                to={getRelativeLink(content?.link?.url)}
                target={content?.link?.target}
                className={'text-c-secondary dark:text-c-dark-primary flex gap-5 mt-[1.5rem]'}
            >
                <ArrowDownLongIcon/>
                <p className={'text-xl text-c-secondary font-medium dark:text-c-dark-primary uppercase'}>{content?.link?.title}</p>
            </Link>
        </div>
    </section>
}

export default Banner;
