import {createContext, ReactNode, useReducer} from 'react';
import Page from '../model/Page';
import Menu from '../model/Menu';
import Settings from '../model/Settings';
import Post from '../model/Post';
import FooterMenu from "../model/FooterMenu";

type ContentContextType = {
    pages: [];
    registrationEnabled: boolean;
    menu: Menu;
    footer: FooterMenu;
    settings: Settings;
    lang: 'pl' | 'en';
    highContrast: boolean;
    fontSize: number;
    increaseFontSize: () => void;
    decreaseFontSize: () => void;
    resetFontSize: () => void;
    toggleHighContrast: () => void;
    setPages: (pages: Page[]) => void;
    setPosts: (posts: Post[]) => void;
    setMenu: (menu: Menu) => void;
    setFooter: (menu: FooterMenu) => void;
    setSettings: (settings: Settings) => void;
    setLang: (lang: string) => void
};

type ActionType = {
    type: string;
    value?: any;
};

const SET_PAGES = 'setPages';
const SET_MENU = 'setMenu';
const SET_FOOTER = 'setFooter';
const SET_SETTINGS = 'setSettings';
const SET_LANG = 'setLang';
const INCREASE_FONT = 'increaseFont';
const DECREASE_FONT = 'decreaseFont';
const RESET_FONT = 'resetFont';
const TOGGLE_CONTRAST = 'toggleContrast';

const MAX_FONT_SIZE = 100;
const MIN_FONT_SIZE = 20;

const INITIAL_CONTENT_STATE: ContentContextType = {
    registrationEnabled: process.env.REACT_APP_NAME === 'OLiJP',
    pages: [],
    menu: {} as Menu,
    footer: {} as FooterMenu,
    settings: {} as Settings,
    lang: 'pl',
    highContrast: false,
    fontSize: 62.5,
    toggleHighContrast: () => {},
    increaseFontSize: () => {},
    decreaseFontSize: () => {},
    resetFontSize: () => {},
    setPages: (pages: Page[]) => {},
    setPosts: (posts: Post[]) => {},
    setMenu: (menu: Menu) => {},
    setFooter: (menu: FooterMenu) => {},
    setSettings: (settings: Settings) => {},
    setLang: (lang: string) => {}
};

const contentReducer = (state: ContentContextType, action: ActionType): ContentContextType => {
    switch (action.type) {
        case SET_PAGES: {
            return {
                ...state,
                pages: action.value,
            };
        }
        case SET_MENU: {
            return {
                ...state,
                menu: action.value,
            };
        }
        case SET_FOOTER: {
            return {
                ...state,
                footer: action.value,
            };
        }
        case SET_SETTINGS: {
            return {
                ...state,
                settings: action.value,
            };
        }
        case SET_LANG: {
            return {
                ...state,
                lang: action.value,
            };
        }
        case DECREASE_FONT: {
            return {
                ...state,
                fontSize: state.fontSize -  5 < MIN_FONT_SIZE ? state.fontSize : state.fontSize - 5,
            };
        }
        case INCREASE_FONT: {
            return {
                ...state,
                fontSize: state.fontSize +  5 > MAX_FONT_SIZE ? state.fontSize : state.fontSize + 5,
            };
        }
        case RESET_FONT: {
            return {
                ...state,
                fontSize: INITIAL_CONTENT_STATE.fontSize,
            };
        }
        case TOGGLE_CONTRAST: {
            return {
                ...state,
                highContrast: !state.highContrast
            };
        }
        default:
            return state;
    }
};

export const ContentContextProvider = ({children}: { children: ReactNode }) => {
    const [contentState, dispatch] = useReducer(contentReducer, INITIAL_CONTENT_STATE, undefined);

    const setPages = (pages: Page[]) => {
        dispatch({type: SET_PAGES, value: pages});
    };
    const setMenu = (menu: Menu) => {
        dispatch({type: SET_MENU, value: menu});
    };
    const setFooter = (menu: FooterMenu) => {
        dispatch({type: SET_FOOTER, value: menu});
    };
    const setSettings = (settings: Settings) => {
        dispatch({type: SET_SETTINGS, value: settings});
    };

    const setLang = (lang: string) => {
        dispatch({type: SET_LANG, value: lang});
    };

    const decreaseFont = () => {
        dispatch({type: DECREASE_FONT});
    };

    const increaseFont = () => {
        dispatch({type: INCREASE_FONT});
    };

    const resetFont = () => {
        dispatch({type: RESET_FONT});
    };

    const toggleContrast = () => {
        dispatch({type: TOGGLE_CONTRAST});
    };

    const contextValue: ContentContextType = {
        ...contentState,
        toggleHighContrast:toggleContrast,
        increaseFontSize: increaseFont,
        decreaseFontSize: decreaseFont,
        resetFontSize: resetFont,
        setPages: setPages,
        setMenu: setMenu,
        setFooter: setFooter,
        setSettings: setSettings,
        setLang: setLang,
    };
    return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

const ContentContext = createContext<ContentContextType>({
    ...INITIAL_CONTENT_STATE,
});

export default ContentContext;
