
const ArrowDownIcon = () => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00001 5.172L11.95 0.222L13.364 1.636L7.00001 8L0.636014 1.636L2.05001 0.221999L7.00001 5.172Z" fill="currentColor"/>
        </svg>

    );
};

export default ArrowDownIcon;
