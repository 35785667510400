type ActionProps = {
    onClick: any;
    isMainPage: boolean;
}

const MenuButton = ({onClick, isMainPage}: ActionProps) => {
    const bgColor = isMainPage ? 'bg-c-secondary dark:bg-c-dark-secondary' : 'bg-c-primary dark:bg-c-dark-primary'
    return (
        <button className="xs:block lg:hidden space-y-2" onClick={onClick}>
            <span className={"block w-10 h-1 " + bgColor}></span>
            <span className={"block w-10 h-1 " + bgColor}></span>
            <span className={"block w-10 h-1 " + bgColor}></span>
        </button>
    )
}

export default MenuButton
