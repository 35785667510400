import {cleanUpHtml} from "../utils/string-tools";
import WPImage from "../model/WPImage";

type Props = {
  content: any
}

const TextWithBigImage = ({content}: Props) => {
  const cleanedText = cleanUpHtml(content.text ? content.text : '')
  const imageProps = content.image ? WPImage.fromApiResponse(content.image) : null

  return <section id={content.section_id} className={'wrapper relative w-full'}>
    <div className={'py-[8rem] h-full flex flex-col gap-[4rem]'}>
      {cleanedText && <div
        className={'text-c-primary dark:text-c-dark-primary w-full md:w-[80%] text-3xl md:text-5xl [&>*>span]:text-c-tertiary [&>*>span]:dark:text-c-dark-primary'}
        dangerouslySetInnerHTML={{__html: cleanedText}}/>}
      {imageProps && imageProps.toComponent('w-full object-cover max-h-[60rem]')}
    </div>
  </section>
}

export default TextWithBigImage;
