import {cleanUpHtml, getEngDate} from "../utils/string-tools";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import WPImage from "../model/WPImage";
import ArrowLeftLongIcon from "../ui/icons/ArrowrLeftLongIcon";
import {useLocation, useNavigate} from "react-router-dom";
import FacebookIcon from "../components/Icons/FacebookIcon";
import LinkedinIcon from "../components/Icons/LinkedinIcon";
import {FacebookShareButton, LinkedinShareButton} from "react-share";

const PostContent = ({content}: any) => {
  const navigate = useNavigate();
  const location = useLocation()
  const text = cleanUpHtml(content.content)
  const {lang} = useContext(ContentContext);
  const authorImage = content.authors[0].image ? WPImage.fromApiResponse(content.authors[0].image) : null;
  const date = lang === 'en' ? getEngDate(content.article_date) : content.article_date;

  return <section
    id={content.section_id}
    className={'wrapper !py-[10rem] relative w-full'}
  >
    <button onClick={() => navigate(-1)} className={'flex items-center font-medium uppercase text-c-primary dark:text-c-dark-primary gap-[1.6rem] mb-[3rem] text-lg'}>
      <ArrowLeftLongIcon />
      <p className={'dark:text-c-dark-primary text-c-primary'}>{lang === 'en' ? 'Back' : 'Wróć'}</p>
    </button>
    <div className={'wrapper !max-w-[797px]'}>
      <div className={'flex items-center flex-wrap gap-[.8rem] mb-[2.4rem]'}>
        {authorImage && <div className={'w-[4.8rem] h-[4.8rem] rounded-[50%] bg-c-tertiary'}>
          {authorImage.toComponent('w-[4.8rem] h-[4.8rem] rounded-[50%] object-cover')}
        </div>}
        <div className={'flex flex-wrap items-center gap-[.8rem]'}>
          {content.authors[0].name && <p className={'text-base text-c-primary dark:text-c-dark-primary overflow-break break-all'}>{content.authors[0].name}</p>}
          {content.authors[0].name && <span className={'w-[.4rem] h-[.4rem] rounded-[50%] bg-c-primary dark:bg-c-dark-primary'}></span>}
          {content.article_date && <p className={'text-base text-c-primary dark:text-c-dark-primary'}>{date.charAt(0).toUpperCase() + date.slice(1)}</p>}
          {content.read_time && <span className={'w-[.4rem] h-[.4rem] rounded-[50%] bg-c-primary dark:bg-c-dark-primary'}></span>}
          {content.read_time && <p className={'text-base text-c-primary dark:text-c-dark-primary'}>{content.read_time}</p>}
        </div>
      </div>
      <h1
        className={'text-3xl md:text-5xl font-bold text-c-primary dark:text-c-dark-primary mb-[4.8rem]'}
      >
        {content.post_title}
      </h1>
      {text && <div
        className={'wysiwyg dark:wysiwyg-dark w-full text-lg'}
        dangerouslySetInnerHTML={{__html: text}}/>}
      {content.post_title && content.short_description && <div
        className={'border-t border-c-tertiary dark:border-c-dark-primary mt-[4.8rem]'}
      >
        <div className={'flex flex-wrap items-center gap-[2.4rem] mt-[2.4rem]'}>
          <p className={'text-xl dark:text-c-dark-primary text-c-primary'}>{lang === 'en' ? 'Share:' : 'Udostępnij:'}</p>
          <FacebookShareButton url={process.env.REACT_APP_DOMAIN + location.pathname}>
            <FacebookIcon />
          </FacebookShareButton>
          <LinkedinShareButton title={content.post_title} summary={content.short_description}  url={process.env.REACT_APP_DOMAIN + location.pathname}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </div>
      </div>}
    </div>
  </section>
}

export default PostContent
